<template lang="pug">
  .top-bar.filters.h-100
    .row.align-items-center.m-0
      .filter.col
        .search.w-100
          AppSearch.w-100(
            :value="filters.searchValue"
            @update="$emit('update-search-value', $event)"
          )
    .filter-actions.d-flex.justify-content-between.align-items-center.w-100
      .add-new
        AppAddNewButton(
          :disabled="isAddNewDisabled"
          title="company_system.add_new"
          @click="$emit('add-new')"
        )
      .right-side-actions
        AppDownloadButton(disabled)
        AppSaveButton.save(
          :disabled="isSaveDisabled"
          title="company_system.save"
          @save="$emit('save')"
        )
</template>

<script>
  export default {
    components: {
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppDownloadButton: () => import("@/components/elements/AppButton/WithIcon/Download"),
      AppAddNewButton: () => import("@/components/elements/AppButton/WithIcon/AddNew"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save")
    },

    props: {
      filters: {
        type: Object,
        default: () => new Object()
      },

      isAddNewDisabled: {
        type: Boolean,
        default: false
      },

      isSaveDisabled: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .top-bar.filters
    padding: 0

    .filter
      +filter-title
      .filter-title
        margin-bottom: 0
        margin-left: 0

      min-width: 210px
      max-width: 25%
      padding: 0

      ::v-deep
        .app-select
          padding-left: 0

      .search
        padding-top: 20px

      &-actions
        margin-top: 32px

        .right-side-actions
          .save
            margin-left: 10px
</style>
